import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/css/style.css';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const app = createApp(App)

app.use(VueSweetalert2);
app.use(router, store);

app.mount('#app');


// createApp(App).mount('#app');
// App.use(router, store);

// App.use(VueSweetalert2);

// new Vue({
//     router,
//     store,
//     render: h => h(App)
//   }).$mount('#app')
