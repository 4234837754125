<template>
  <div id="principal" class="">
    <div v-if="!permissao">
      <div class="fixed-top navbar-principal">
        <div class="div-img">
          <img id="nvLogo" class=" navbar-logo nav-logo img-topo" src="./assets/img/logo-garage-branco.png" >
        </div>
        <nav class="navbar navbar-expand ">
          <div class="nav-item">
            <div class="row text-center align-items-center" style="display:block;">
              <div class="btn-group col col-md-8 col-sm-12" role="group">
                <button type="button" class="btn btn-social" @click="opemSocialMedia('facebook')" data-toggle="tooltip" data-placement="bottom" title="Facebook"><i class="fa fa-facebook-f"></i></button>
                <button type="button" class="btn btn-social" @click="opemSocialMedia('twitter')" data-toggle="tooltip" data-placement="bottom" title="X Twitter"><i class="fa fa-twitter"></i></button>
                <button type="button" class="btn btn-social" @click="opemSocialMedia('instagram')" data-toggle="tooltip" data-placement="bottom" title="Instagram"><i class="fa fa-instagram"></i></button>
                <button type="button" class="btn btn-social" @click="opemSocialMedia('youtube')" data-toggle="tooltip" data-placement="bottom" title="Youtube"><i class="fa fa-youtube"></i></button>
                <button type="button" class="btn btn-social" @click="opemSocialMedia('whatsapp')" data-toggle="tooltip" data-placement="bottom" title="WhatsApp"><i class="fa fa-whatsapp"></i></button>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div id="logo" :style="`height: ${heightTela}px;`">
        <img class="logo-principal" src="./assets/img/logo-garage-branco.png">
      </div>
      <div id="sobre" class="row">
        <div class="col col-md-6 col-sm-12  col-12 text-center">
          <div>
            <p class="text-capa">
            A banda GARAGEBOX surgiu no ano de 2016 na cidade de Goianésia – GO e deste então vem se destacando no cenário musical regional pela sua qualidade tanto pela execução quanto também por seu repertório que abrange desde clássicos do Rock à POP nacional e internacional.
            </p>
            <p class="text-capa">
            Pela sua irreverência e qualidade musical, a banda já se apresentou em grandes eventos culturais e sociais como nas duas Últimas edições do Encontro de Motociclistas em Goianésia – GO (2017,2018 e 2022), Dia mundial do Fusca em Jaraguá – GO (2017), Encontro de Jipeiros (2018) em Barro Alto – GO, e diversos eventos realizados na região do Vale do São Patrício, como também em casas tradicionais do cenário goiano, dentre essas apresentações se destacam os shows realizados na Capital Goiana e demais cidades, dentre outros.
            </p>
            <p class="text-capa">
            Por esses e outros motivos, que damos a certeza da realização de uma grandiosa apresentação em seu evento.
            </p>
            <a class="btn btn-success" href="release.pdf" download="Release-Garagebox">Download Release</a>
          </div>
        </div>
        <div class="div-img-capa col col-md-6 col-sm-12 col-12 text-center">
          <img class="img-capa" src="./assets/img/capa-banda.jpeg">
        </div>
      </div>
      <div id="membros" class="row">
        <div class="div-membros col col-md-3 col-sm-5 col-12">
          <img class="img-membro" src="./assets/img/adriano.jpg">
          <h1 class="h1-membro">Adriano</h1>
          <h3 class="h3-membro">Baixo</h3>
        </div>
        <div class="div-membros col col-md-3 col-sm-5 col-12">
          <img class="img-membro" src="./assets/img/gefter.jpg">
          <h1 class="h1-membro">Gefter</h1>
          <h3 class="h3-membro">Bateria</h3>
        </div>
        <div class="div-membros col col-md-3 col-sm-5 col-12">
          <img class="img-membro" src="./assets/img/lucas.jpg">
          <h1 class="h1-membro">Lucas</h1>
          <h3 class="h3-membro">Guitarra</h3>
        </div>
        <div class="div-membros col col-md-3 col-sm-5 col-12">
          <img class="img-membro" src="./assets/img/stenio.jpg">
          <h1 class="h1-membro">Stênio</h1>
          <h3 class="h3-membro">Vocal</h3>
        </div>
      </div>
      <div id="fotos" class="row">
        <div class="col col-md-4 col-12"><img class="galeria-item" src="./assets/img/show1.jpeg"></div>
        <div class="col col-md-4 col-12"><img class="galeria-item" src="./assets/img/show2.jpeg"></div>
        <div class="col col-md-4 col-12"><img class="galeria-item" src="./assets/img/show3.jpeg"></div>
      </div>
      <div id="videos" class="row text-center">
        <iframe class="video col col-md-6 col-12"  width="510" height="490" src="https://www.youtube.com/embed/bvw5FyVsiWg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <div class="col col-md-6 col-12">
        <h1 class="text-video">Entre em contato conosco através dos links disponíveis ao topo do nosso site e garantimos a alegria e um grande show em seu evento ou no seu empreendimento!</h1>
        </div>
      </div>
      <div id="footer" class="d-flex align-items-end text-center">
        <div style="width:100%;">
          <div class="row" style="height: 50px;">
            <div class="col col-12">
              <img class="logo-rodape" src="./assets/img/logo-garage-branco.png">
            </div>
          </div>
          <div class="row" style="height: 50px;">
            <div class="d-grid gap-2 d-md-block">
              <button @click="showAcessoRestrito()" type="button" class="btn btn-secondary"><i class="fa fa-lock"></i> Acesso Restrito</button>
            </div>
          </div>
          <div class="row" style="height: 60px; padding-top:10px;">
            <div class="col col-12">
              <h4>Desenvolvido por <strong>Lucas Marllon</strong>.</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="permissao" id="repertorio" class="div-repert">
      <nav class="navbar navbar-expand navbar-show fixed-top">
        <div class="nav-item">
          <div class="row align-items-center text-end">
            <div class="logo-nav-edit text-center col col-md-6 col-sm-12" >
                <img id="nvLogoEdit" class=" navbar-logo nav-logo nav-logo-show" src="./assets/img/logo-garage-branco.png">
            </div>
            <div class="col col-md-6 col-sm-12 text-end" role="group">
              <div class="d-grid gap-2 d-md-block text-end">
                <button @click="voltarSite()" class="btn btn-secondary" type="button"><i class="fa fa-window-close-o"></i> Voltar a Página Principal</button>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <h1 class="mb-3" style="margin-top: 80px; font-size:50px;">Repertório</h1>
      <div v-if="mostraImputFile">
        <h3>Selecione o arquivo com o repertório no formato Excel:</h3>
        <input @change="carregaLista" type="file"  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" class=" mb-3 mt-3" id="repert-input" aria-label="Upload" style="width: 300px;">
      </div>
      <div v-if="!mostraImputFile" class="row" id="resultado-repert">
        <!-- <div v-if="listaMusicas.length > 0 || (montarRepertorio && musicasSelecionadas.length == 0)" class="row" id="resultado-repert"></div> -->
        <div class="col" :class="montarRepertorio ? 'col-md-8' : 'col-md-12'">
          <h1>Listagem das Músicas</h1>
          <div id="filtros-repert" style="background-color:lightgray; padding:10px;">
            <div class="d-grid gap-2 d-md-block">
              <button v-if="listaMusicas.length > 0 && !montarRepertorio" @click="() => montarRepertorio = true" class="btn btn-primary btn-agrupados" type="button"><i class="fa fa-list"></i> Montar Repertório</button>
              <button @click="resetaLista()" v-if="listaMusicas.length > 0" class="btn btn-info btn-agrupados" type="button"><i class="fa fa-solid fa-upload"></i> Importar Nova Lista</button>
              <button @click="print(false)" class="btn btn-warning btn-agrupados" type="button"><i class="fa fa-print"></i> Imprimir</button>
              <button v-if="!montarRepertorio" @click="() => abrirModalHistorico = true" class="btn btn-dark btn-agrupados" type="button"><i class="fa fa-history"></i> Histórico de Repertórios</button>
              <button @click="() => mostraFiltros = !mostraFiltros" class="btn btn-secondary" type="button"><i class="fa fa-solid" :class="mostraFiltros ? 'fa-eye-slash' : 'fa-eye'"></i> {{ mostraFiltros ? 'Esconder Filtros' : 'Mostrar Filtros'}}</button>
            </div>
            <div v-if="mostraFiltros" class="text-start" style="background-color:white; padding: 10px;">
              <div class="row">
                <div class="col col-md-3">
                  <label for="selectNacionalidade" class="form-label">Nacionalidade</label>
                  <select v-model="filtros.nacionalidade" @change="filtrar" class="form-select" id="selectNacionalidade" aria-label="Nacionalidade">
                    <option value="">Todas</option>
                    <option value="NACIONAL">Nacionais</option>
                    <option value="INTERNACIONAL">Internacionais</option>
                  </select>
                </div>
                <div class="col col-md-3">
                  <label for="selectGenero" class="form-label">Gênero</label>
                  <select v-model="filtros.genero" @change="filtrar" class="form-select" id="selectGenero" aria-label="Genero">
                    <option value="">Todos</option>
                    <option v-for="item in listAllGeneros" :value="item" :key="item">{{ item }}</option>
                  </select>
                </div>
                <div class="col col-md-6">
                  <label for="selectGenero" class="form-label">Palavra-chave</label>
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-search"></i></span>
                    <input v-model="filtros.palavraChave" @keyup="filtrarPalavraChave(false)" type="text" class="form-control" placeholder="Digite aqui a sua busca..." aria-label="Filtros" aria-describedby="basic-addon1">
                  </div>
                </div>
              </div>
          </div>
          </div>
          <div id="table-repertorio">
            <table id="table-print" class="table table-striped table-hover align-middle">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th align="left" scope="col">Música</th>
                  <th scope="col">Cantor/banda</th>
                  <th scope="col">Nacionalidade</th>
                  <th scope="col">VS</th>
                  <th scope="col">Duração</th>
                  <th scope="col">Gênero</th>
                  <th v-if="montarRepertorio" scope="col">Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in listaMusicasFiltradas" :key="`id-lista-${index}`">
                  <th scope="row">{{ index + 1 }}</th>
                  <td align="left" >{{ item.descricao }}</td>
                  <td>{{ item.cantor }}</td>
                  <td>{{ item.nacionalidade }}</td>
                  <td>
                    <span v-if="item.vs" class="badge bg-primary">Possui</span>
                    <span v-else class="badge bg-secondary">Não Possui</span>
                    <span v-if="item.vs && item.editado" class="badge bg-success">Editado</span>
                  </td>
                  <td>{{ item.duracao }}</td>
                  <td>{{ item.genero }}</td>
                  <td v-if="montarRepertorio">
                    <button @click="adicionarMusica(item, index)" class="btn btn-success rounded-circle btn-sm"><i class="fa fa-plus"></i></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="montarRepertorio" class="col col-md-4">
          <h1>Selecionadas</h1>
          <div class="row" style="background-color:lightgray; padding:10px;">
            <div class="d-grid gap-2 d-md-block">
              <button @click="() => musicasSelecionadas.push({descricao: ' '})" v-if="listaMusicas.length > 0" class="btn btn-primary btn-agrupados" type="button"><i class="fa fa-percent"></i> Inserir Separador</button>
              <button @click="finalizarRepertorio()" class="btn btn-success btn-agrupados" type="button"><i class="fa fa-check"></i> Finalizar/Imprimir </button>
              <button @click="cancelarMontarRepertorio" class="btn btn-warning btn-agrupados" type="button"><i class="fa fa-ban"></i> Cancelar</button>
            </div>
          </div>
          <div class="text-start" style="background-color: white; overflow: auto;" :style="`height: ${heightTela - 510}px;`">
            <table v-if="musicasSelecionadas.length > 0" class="table table-borderless table-sm align-middle table-striped">
              <draggable v-model="musicasSelecionadas" tag="tbody">
                <template #item="{ element: meal , index }">
                  <tr class="tr-new-repert">
                    <td>{{ index + 1 }}</td>
                    <td v-if="meal.descricao != ' '"><strong>{{ `${meal.descricao} (${meal.tom})` }}</strong></td>
                    <td v-else> <hr class="hr" /></td>
                    <td align="right"><button @click="removerMusica(meal, index)" class="btn btn-danger rounded-circle btn-sm" style="margin: 0px;"><i class="fa fa-trash"></i></button></td>
                  </tr>
                </template>
              </draggable>
            </table>
            <div v-else class="alert alert-secondary text-center" role="alert" style="margin: 10px; font-size:16px;">
              Selecione músicas na lista ao lado para montar o repertório!
            </div>
          </div>
          <div class="row text-start" style="background-color:lightgray; padding:10px; font-size:14px;">
            <div class="col col-md-6">
              <label><strong>Total de Músicas Selecionadas: </strong><span class="badge rounded-pill bg-primary">{{ musicasSelecionadas.filter(sel => sel.descricao != ' ').length }}</span></label>
              <label><strong>Duração Prévia do Show: </strong><span class="badge rounded-pill bg-primary">{{ totalTempoShow.format('HH:mm:ss')}}</span></label>
              <label><strong>Músicas sem tempo definido: </strong><span class="badge rounded-pill" :class="musicasSelecionadas.filter(sel => sel.minutos == null && sel.descricao != ' ').length == 0 ? 'bg-primary' : 'bg-danger'">{{ musicasSelecionadas.filter(sel => sel.minutos == null && sel.descricao != ' ').length}}</span></label>
            </div>
            <div class="col col-md-6">
              <label><strong>Qtde de Músicas Nacionais: </strong><span class="badge rounded-pill bg-primary">{{musicasSelecionadas.filter(sel => sel.nacionalidade == 'NACIONAL').length}}</span></label>
              <label><strong>Qtde de Músicas Internacionais: </strong><span class="badge rounded-pill bg-primary">{{musicasSelecionadas.filter(sel => sel.nacionalidade == 'INTERNACIONAL').length}}</span></label>
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="height: 50px;">
        <div class="col col-12">
          <img class="logo-rodape" src="./assets/img/logo-garage-preto.jpeg">
        </div>
      </div>
    </div>
    <div class="modal" 
      :class="abrirModalHistorico ? 'fade show' : ''" 
      role="dialog"
      tabindex="-1"
      :style="abrirModalHistorico ? 'display: block;' : 'display: none;'" 
      :aria-hidden="abrirModalHistorico ? 'false' : 'true'"
    >
      <div class="modal-dialog" role="document" style="max-width:70%; margin-top:100px;" >
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title">Histórico de Repertórios</h3>
          </div>
          <div class="modal-body">
            <table id="table-historico" class="table table-striped table-hover align-middle">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Dada Cadastro</th>
                  <th align="left" scope="col">Descrição</th>
                  <th scope="col">Data Evento</th>
                  <th scope="col">Qtd. Músicas</th>
                  <th scope="col">Duração Prévia </th>
                  <th scope="col">Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in historico" :key="`hist-${index}`">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>{{ formataData(item.dataCadastro, 'DD/MM/YYYY HH:mm:ss') }}</td>
                  <td align="left" >{{ `${item.titulo} ${item.observacao ? item.observacao : ''}` }}</td>
                  <td>{{ item.data }}</td>
                  <td><span class="badge bg-primary">{{ item.musicasSelecionadas.filter(sel => sel.descricao != ' ').length }}</span></td>
                  <td>{{ formataData(item.tempoShow, 'HH:mm:ss') }}</td>
                  <td>
                    <button 
                      @click="showRepertorio(item)"
                      class="btn btn-primary btn-sm"
                      data-toggle="tooltip" data-placement="top" title="Visualizar"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                    <button 
                      @click="carregarRepertorioHistorico(item, index)"
                      class="btn btn-success btn-sm"
                      data-toggle="tooltip" data-placement="top" title="Reabrir"
                      style="margin-left:10px;"
                    >
                      <i class="fa fa-pencil"></i>
                    </button>
                    <button 
                      @click="removerRepertorioHistorico(index)"
                      class="btn btn-danger btn-sm"
                      data-toggle="tooltip" data-placement="top" title="Apagar"
                      style="margin-left:10px;"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button @click="() => abrirModalHistorico = false" type="button" class="btn btn-secondary" data-dismiss="modal">Sair</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" 
      :class="abrirModalVisualiza ? 'fade show' : ''" 
      role="dialog"
      tabindex="-1"
      :style="abrirModalVisualiza ? 'display: block;' : 'display: none;'" 
      :aria-hidden="abrirModalVisualiza ? 'false' : 'true'"
    >
      <div class="modal-dialog" role="document" style="max-width:70%; margin-top:100px;" >
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title">{{ `${visualizaRepertorio.titulo} ${visualizaRepertorio.observacao ? visualizaRepertorio.observacao : ''} ` }}</h3>
          </div>
          <div v-if="abrirModalVisualiza" class="modal-body">
            <div class="text-start" style="height:500px; overflow: auto;">
              <table id="table-lista-musicas-historico" class="table table-borderless table-sm align-middle table-striped">
                <tbody>
                  <tr class="tr-new-repert" v-for="(musica, index) in visualizaRepertorio.musicasSelecionadas" :key="`id-hist-${index}`">
                    <td>{{ index + 1 }}</td>
                    <td v-if="musica.descricao != ' '"><strong>{{ `${musica.descricao} (${musica.tom})` }}</strong></td>
                    <td v-else> <hr class="hr" /></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row text-start" style="background-color:lightgray; padding:10px; font-size:14px;">
              <div class="col col-md-4">
                <label><strong>Data do Evento: </strong>{{ visualizaRepertorio.data }}</label>
                <label><strong>Data/hora da criação: </strong>{{ visualizaRepertorio.dataCadastroStr }}</label>
                <label><strong>Total de Músicas Selecionadas: </strong><span class="badge rounded-pill bg-primary">{{ visualizaRepertorio.musicasSelecionadas.filter(sel => sel.descricao != ' ').length }}</span></label>
              </div>
              <div class="col col-md-4">
                <label><strong>Duração Prévia do Show: </strong><span class="badge rounded-pill bg-primary">{{ visualizaRepertorio.totalTempoShow.format('HH:mm:ss')}}</span></label>
                <label><strong>Músicas sem tempo definido: </strong><span class="badge rounded-pill" :class="visualizaRepertorio.musicasSelecionadas.filter(sel => sel.minutos == null && sel.descricao != ' ').length == 0 ? 'bg-primary' : 'bg-danger'">{{ visualizaRepertorio.musicasSelecionadas.filter(sel => sel.minutos == null && sel.descricao != ' ').length}}</span></label>
              </div>
              <div class="col col-md-4">
                <label><strong>Qtde de Músicas Nacionais: </strong><span class="badge rounded-pill bg-primary">{{visualizaRepertorio.musicasSelecionadas.filter(sel => sel.nacionalidade == 'NACIONAL').length}}</span></label>
                <label><strong>Qtde de Músicas Internacionais: </strong><span class="badge rounded-pill bg-primary">{{visualizaRepertorio.musicasSelecionadas.filter(sel => sel.nacionalidade == 'INTERNACIONAL').length}}</span></label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button @click="() => abrirModalVisualiza = false" type="button" class="btn btn-secondary" data-dismiss="modal">Sair</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

$(window).scroll(function() {
  if ($(window).scrollTop() > 500) {
    $(".navbar-principal").addClass("navbar-show");
    $("#nvLogo").removeClass("nav-logo");
    $("#nvLogo").addClass("nav-logo-show");
  } else {
    $(".navbar-principal").removeClass("navbar-show");
    $("#nvLogo").removeClass("nav-logo-show");
    $("#nvLogo").addClass("nav-logo");
  }
});


import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";
import $ from 'jquery';
import { ref } from 'vue';
import draggable from 'vuedraggable';
import moment from 'moment';

export default {
  name: 'App',
  components: {
    draggable,
  },
  data() {
    return {
      senha: '@garage@102030',
      permissao: false,
      listaMusicas: [],
      listaMusicasParcial: [],
      listaMusicasFiltradas: [],
      montarRepertorio: false,
      musicasSelecionadas: ref([]),
      mostraFiltros: true,
      mostraImputFile: true,
      listAllGeneros: [],
      totalTempoShow: moment('2000-01-01 00:00:00'),
      filtros: {
        nacionalidade: '',
        genero: '',
        palavraChave: '',
      },
      impressao: {
        titulo: '',
        data:'',
      },
      historico: [],
      abrirModalHistorico: false,
      indexEditHistórico: -1,
      visualizaRepertorio: {},
      abrirModalVisualiza: false,
    }
  },
  methods:{
    async showAcessoRestrito() {
      // Use sweetalert2
      // this.$swal('Hello Vue world!!!');

      const { value: senha } = await this.$swal.fire({
      title: "Acesso Restrito",
      input: "password",
      icon: "warning",
      inputLabel: "Digite a senha para acessar:",
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return "Preencha corretamente a senha de acesso!";
        }
      }
    });
    if (senha) {
      if (senha != this.senha) {
        this.$swal.fire({
          icon: "error",
          title: "Oops...",
          text: "As senhas não conferem!",
          showCancelButton: true,
          confirmButtonText: "Tentar Novamente",
          cancelButtonText: `Cancelar`
        }).then((result) => {
          if (result.isConfirmed) {
            this.showAlert();
          }
        });
      } else {
        this.permissao = true;
        this.$swal.fire({
          icon: "success",
          title: "Acesso Liberado!",
          showConfirmButton: false,
          timer: 2000
        });
      }
    }
    },
    async carregaLista(event) {
      var XLSX = require('xlsx');
      this.file = event.target.files ? event.target.files[0] : null;
      if (this.file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: 'binary' });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
          if (data.length > 0) {
            data.forEach((element,index) => {
              if (index > 0) {
                this.listaMusicas.push({
                  descricao: element[0],
                  cantor: element[1],
                  nacionalidade: element[2],
                  vs: element[3] == 'SIM' ? true : false,
                  editado: element[4] == 'SIM' ? true : false,
                  tom: element[5],
                  genero: element[6],
                  minutos: element[7],
                  segundos: element[8],
                  duracao: element[7] ? moment(`2000-01-01 00:${element[7]}:${element[8]}`).format('mm:ss') : null,
                });
                this.carregaGenero(element[6]);
              }
            });

            this.listaMusicasFiltradas = [...this.listaMusicas];
            this.listaMusicasParcial = [...this.listaMusicas];
            this.mostraImputFile = false;
          }
        }

        reader.readAsBinaryString(this.file);

      }
    },
    opemSocialMedia(item) {
      let link = 'https://www.secure.instagram.com/bandagaragebox/?hl=pt-br';

      if (item == 'facebook') {
        link = 'https://www.facebook.com/bandagaragebox';

      } else if (item == 'twitter') {
        link = 'https://twitter.com/bandagaragebox';

      } else if (item == 'youtube') {
        link = 'https://www.youtube.com/@bandagaragebox';
      } else if (item == 'whatsapp') {
        let number = '5562984268161';
        let msg = 'Olá! Gostaria de informações sobre a banda Garagebox.';
        link = `https://api.whatsapp.com/send?phone=${encodeURIComponent(number)}&text=${encodeURIComponent(msg)}`;
      }

      window.open(link, '_blank');
    },
    adicionarMusica(item, index) {
      this.musicasSelecionadas.push(item);
      this.listaMusicasFiltradas.splice(index, 1);
      this.totalTempoShow.add(item.minutos, "minutes").add(item.segundos, "seconds");
    },
    removerMusica(item, index) {
      this.listaMusicasFiltradas.push(item);
      this.musicasSelecionadas.splice(index, 1);
      this.totalTempoShow.subtract(item.minutos, "minutes").subtract(item.segundos, "seconds");
    },
    carregaGenero(genero) {
      const existe = this.listAllGeneros.find(gen => gen == genero);
      if (!existe) this.listAllGeneros.push(genero);
    },
    async filtrar() {
      this.listaMusicasFiltradas = [...this.listaMusicas];

      if (this.filtros.nacionalidade != '') {
        this.listaMusicasFiltradas = this.listaMusicasFiltradas.filter(item => item.nacionalidade == this.filtros.nacionalidade);
      }
      if (this.filtros.genero != '') {
        this.listaMusicasFiltradas = this.listaMusicasFiltradas.filter(item => item.genero == this.filtros.genero);
      }
      this.listaMusicasParcial = [...this.listaMusicasFiltradas];
      this.filtrarPalavraChave(true);
    },
    filtrarPalavraChave(filtroTotal) {
      const palavra = this.filtros.palavraChave;
      let arrayMov = this.listaMusicasParcial;

      if (palavra != '') {
        this.listaMusicasFiltradas = arrayMov.filter(data =>{
            if(data.descricao.trim().toUpperCase().includes(palavra.trim().toUpperCase()) ||
              data.cantor.trim().toUpperCase().includes(palavra.trim().toUpperCase()) ) return data;
            return null;
        });
      } else {
        console.log('text vazio-', filtroTotal);
        if (!filtroTotal) this.listaMusicasFiltradas = arrayMov;
      }
    },
    async finalizarRepertorio() {
      if (this.musicasSelecionadas.length > 0) {

        if(this.impressao.titulo == '') {
          const { value: titulo } = await this.$swal.fire({
            icon: "question",
            title: "Dados Complementares",
            input: "text",
            inputLabel: "Digite um título para o repertório:",
            inputPlaceholder: "Casamento Geraldo e Filomena",
            showCancelButton: true,
            confirmButtonText: "Próximo",
            cancelButtonText: `Sair`
          })
          this.impressao.titulo = titulo;
        }

        if(this.impressao.data == '') {
          const { value: data } = await  this.$swal.fire({
            icon: "question",
            title: "Dados Complementares",
            input: "text",
            inputLabel: "Digite a data que será o show:",
            inputPlaceholder: "10/10/2026",
            showCancelButton: true,
            confirmButtonText: "Gerar PDF",
            cancelButtonText: `Sair`
          });

          this.impressao.data = data;
        }
        
        this.historico.push({
          dataCadastro: moment(),
          titulo: this.impressao.titulo,
          data: this.impressao.data,
          musicasSelecionadas: this.musicasSelecionadas,
          tempoShow: this.totalTempoShow,
          observacao: this.indexEditHistórico >= 0 ? `(Alterado em ${moment().format('DD/MM/YYYY HH:mm:ss')})` : null,
        }); 

        await localStorage.setItem('gbx-repertorios', JSON.stringify(this.historico));
        await this.print(true);
        await this.cancelarMontarRepertorio();

      }
    },
    returnHtmlStringSelecionados() {
      let str = '<div style="margin: 20px 10px 5px 50px;">';
      this.musicasSelecionadas.forEach(item => {
        str += `<label class="label-selecionados"><strong>${item.descricao} ${item.descricao != ' ' ? `(${item.tom}) ` : ''} ${item.editado ? '<span class="badge rounded-pill bg-dark">VS</span>' : ''}</strong></label><br />`;
      });
      str += '</div>';
      return str;
    },
    print(selecionadas) {
      let tituloDoc = '';
      let filtros = '';
      let body = '';
      let footer = '';
      let larguraCol = (this.widthTela / 12);
      if (!selecionadas) {
        tituloDoc = `<h2>Listagem Das Músicas Para Repertório</h2>`;
        if (this.filtros.genero != '' || this.filtros.nacionalidade != ''|| this.filtros.palavraChave != '') {
          filtros = `
            <div class="text-start">
              <label><strong>Filtros Aplicados: </strong> 
          `;
          if (this.filtros.genero != '') filtros += `<span class="badge bg-dark">${this.filtros.genero}</span>`;
          if (this.filtros.nacionalidade != '') filtros += `<span class="badge bg-dark">${this.filtros.nacionalidade}</span>`;
          if (this.filtros.palavraChave != '') filtros += `<span class="badge bg-dark">PALAVRA-CHAVE: "${this.filtros.palavraChave.toUpperCase()}"</span>`;
          filtros += '</label></div>';
        }
        body = document.getElementById("table-repertorio").innerHTML;
      } else {
        tituloDoc = `<h4>${this.impressao.titulo != ''? this.impressao.titulo : 'Repertório'}</h4>`;
        body = this.returnHtmlStringSelecionados();
        const qtdMusicas = this.musicasSelecionadas.filter(sel => sel.descricao != ' ').length;
        const qtdMusicasSemTime = this.musicasSelecionadas.filter(sel => sel.descricao != ' ' && sel.minutos == null).length;
        footer = `
          <div class="footer fixed">
            <div class="row">
              <div class="col col-12"><hr class="hr" /></div>
            </div>
            <div class="row">
              <div class="col col-4">
                <label><strong>Qtd. Músicas: </strong><span class="badge bg-dark">${qtdMusicas}</span></label>
              </div>
              <div class="col col-4">
                <label><strong>Tempo Show: </strong><span class="badge bg-dark">${this.totalTempoShow.format('HH:mm:ss')}</span></label>
              </div>
              <div class="col col-4">
                <label><strong>Qtd. Músicas sem Tempo inserido: </strong><span class="badge ${qtdMusicasSemTime > 0 ? 'bg-danger' : 'bg-dark'}">${qtdMusicasSemTime}</span></label>
              </div>
            </div>
          </div>
          
        `;
      }
      const caminhoLogo = 'https://scontent.fbsb21-1.fna.fbcdn.net/v/t39.30808-6/419716225_723373229888093_5227142673549455115_n.jpg?stp=cp6_dst-jpg&_nc_cat=104&ccb=1-7&_nc_sid=dd5e9f&_nc_ohc=22AXXN4bMqEAX-vDCjF&_nc_ht=scontent.fbsb21-1.fna&oh=00_AfBhtr_B78v6Vr9ywavOxg8h69REJxNLmxC75NyHlPk4Ww&oe=65A7FEA4';
      const cssAdicional = `
        <style type="text/css">
          .table-print {
            margin-top: 20px;
          }

          .badge {
            margin-left: 5px;
            color: #FAFAFA;
          }

          .label-selecionados {
            font-size: 22px;
            margin-bottom: 0px;
          }

          // .footer {
          //     position: absolute;
          //     bottom: 0;
          //     width: 100%;
          // }

          .fixed {
            position: fixed;
            bottom: 0;
            width: ${larguraCol * 7 }px;
            margin: 0px;
          }
        </style>
      `;
      let textInicio = `
        <!DOCTYPE html>
        <html lang="pt-br">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <meta http-equiv="X-UA-Compatible" content="ie=edge">
            <title>GARAGEBOX - Repertório</title>
            ${cssAdicional}
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.0.0/css/font-awesome.css" integrity="sha512-72McA95q/YhjwmWFMGe8RI3aZIMCTJWPBbV8iQY3jy1z9+bi6+jHnERuNrDPo/WGYEzzNs4WdHNyyEr/yXJ9pA==" crossorigin="anonymous" referrerpolicy="no-referrer" />
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
        </head>
        <body onload="window.print()">
                        <form class="d-print-none bg-dark text-center pt-4 pb-4">
                            <input class="btn btn-info mr-3" type="button" value="Sair" onClick="window.close()" />
                            <input class="btn btn-success" type="button" value="Imprimir" onClick="window.print()" />
                        </form>
                            <div class="row">
                                <div style="min-height:${this.heightTela}px;" class="col d-print-none col-md-2 bg-dark"></div>
                                <div class="col col-md-8" >
                                  <div class='text-center'>
                                    <img style="height:30px; margin-bottom:10px;" src="${caminhoLogo}" alt="Logo">
                                    ${tituloDoc}
                                    <h6 style=" margin-bottom:30px;">${this.impressao.data != '' ? this.impressao.data : ' '}</h6>
                                  </div>
      `;

      let textFinal = `
            </div>
            <div style="min-height:${this.heightTela}px;" class="col d-print-none col-md-2 bg-dark"></div>
          </div>
        </body>
      </html>
      `;

      //------ Abre nova página para impressão -----------------------
      const WinPrint = window.open(
        "",
        "",
        `left=0,top=0,width=${this.widthTela},height=${this.heightTela},toolbar=0,scrollbars=0,status=0`
      );

      WinPrint.document.write(
          textInicio  + filtros + body + footer + textFinal
      );

      WinPrint.document.onload = function(e){ 
        console.log("Entrou no onload->", e);
        WinPrint.print();
      }

        this.impressao.titulo = '';
        this.impressao.data = '';
    
    },
    resetaLista() {
      this.mostraImputFile = true;
      this.listaMusica = [];
      this.listaMusicasParcial = [];
      this.listaMusicasFiltradas = [];
      this.montarRepertorio = false;
      this.musicasSelecionadas = [];
      this.mostraFiltros = true,
      this.listAllGeneros = [];
      this.totalTempoShow = moment('2000-01-01 00:00:00');
      this.filtros = {
        nacionalidade: '',
        genero: '',
        palavraChave: '',
      };
      this.impressao = {
        titulo: '',
        data:'',
      };
    },
    voltarSite() {
      this.resetaLista();
      this.permissao = false;
    },
    cancelarMontarRepertorio() {
      this.montarRepertorio = false;
      this.musicasSelecionadas = [];
      this.totalTempoShow = moment('2000-01-01 00:00:00');
      console.log('PArcial->', this.listaMusicasParcial);
      this.listaMusicasFiltradas = this.listaMusicasParcial;
      this.filtrarPalavraChave();
    },
    buscaHistoricoRepertorio() {
      const retornoHist = localStorage.getItem('gbx-repertorios');
      if (retornoHist) this.historico = JSON.parse(retornoHist);
    },
    carregarRepertorioHistorico(item, index) {
      this.indexEditHistórico = index;
      this.musicasSelecionadas = [...item.musicasSelecionadas];
      this.totalTempoShow = moment(item.tempoShow);
      this.montarRepertorio = true;

      this.impressao.titulo = item.titulo;
      this.impressao.data = item.data;

      this.musicasSelecionadas.forEach(musica => {
        if (musica.descricao != ' ') {
          const idx = this.listaMusicasFiltradas.findIndex(item => item.descricao == musica.descricao && item.cantor == musica.cantor);
          if (idx >= 0) this.listaMusicasFiltradas.splice(idx, 1);
        }
      });
      this.abrirModalHistorico = false;

    },
    removerRepertorioHistorico(index) {
      this.$swal.fire({
        icon:"question",
        title: "Deseja realmente excluir esse repertório?",
        showCancelButton: true,
        confirmButtonText: "Excluir",
        cancelButtonText: `Cancelar`
      }).then((result) => {
        if (result.isConfirmed) {
          this.historico.splice(index, 1);
          localStorage.setItem('gbx-repertorios', JSON.stringify(this.historico));
          this.$swal.fire("Repertório apagado com sucesso!", "", "success");
        }
      });
    },
    showRepertorio(repertorio) {
      repertorio.totalTempoShow = moment(repertorio.tempoShow);
      repertorio.dataCadastroStr = moment(repertorio.dataCadastro).format('DD/MM/YYYY HH:mm:ss')
      this.visualizaRepertorio = repertorio;
      this.abrirModalVisualiza = true;
    },
    formataData(data, formato) {
      let dataMoment = moment(data);
      return dataMoment.format(formato);
    }
  },
  computed: {
    heightTela() {
      return window.screen.height;
    },
    widthTela() {
      return window.screen.width;
    },
  },
  created() {
    this.buscaHistoricoRepertorio();
  }
}
</script>

<style>
  .div-repert {
    padding: 20px;;
  }

  .btn-agrupados {
    margin: 10px;
  }

  .table {
    font-size: 16px;
  }

  .tr-new-repert {
    cursor: grab;
  }

  .badge {
    margin:2px;
  }

  .form-label{
    font-size: 15px;
    margin-left: 3px;
    margin-bottom: 0px;
  }

  .div-response {
    max-height: 380px;
    overflow: auto;
  }

  .modal {
    background-color: rgba(71, 71, 71, 0.5);
  }

  .back-modal {
    background-color: gray;
    opacity: 0.5;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  .div-img {
    display: block;
    padding-top: 10px;
  }

  .img-topo{
    margin-left: auto;
    margin-right: auto;
  }
</style>
